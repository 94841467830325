<div>
  <div class="dock-inner route-container" *ngIf="!utmRoutes.selectedStop">
    <div class="pad">
      <div class="audio-player-container" *ngIf="selectedRoute?.audio">
        <app-audio-player [audioUrl]="selectedRoute?.audio" />
      </div>

      <app-edit-button
        [nid]="utmRoutes.selected.getValue()?.nid"
      ></app-edit-button>

      <h2>
        {{
          utmTranslate.getAsEnglishIfApplicable(
            selectedRoute,
            "title",
            "title_english"
          )
        }}
      </h2>
      <div class="route-container-inner">
        <div
          class="route-container-teaser"
          [innerHtml]="
            utmTranslate.getAsEnglishIfApplicable(
              selectedRoute,
              'teaser',
              'teaser_english'
            )
          "
        ></div>
        <div
          class="route-container-text"
          [innerHtml]="
            utmTranslate.getAsEnglishIfApplicable(
              selectedRoute,
              'text',
              'text_english'
            )
          "
          *ngIf="
            utmTranslate.getAsEnglishIfApplicable(
              selectedRoute,
              'text',
              'text_english'
            )
          "
        ></div>
        <a
          class="route-button route-start"
          id="route_start"
          (click)="utmRoutes.selectStopByIdx(0)"
          >{{ "startRoute" | translate }}</a
        >
      </div>

      <div class="photo-hold">
        <div class="photo">
          <div>
            <!-- TODO: Fix clicking does not trigger viewer -->
            <app-image-viewer
              [imageSrc]="selectedRoute?.photo"
              [imageAlt]="
                utmTranslate.getAsEnglishIfApplicable(
                  selectedRoute,
                  'title',
                  'title_english'
                )
              "
            ></app-image-viewer>
          </div>
        </div>
        <div class="media-source">
          <div class="views-element-container">
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dock-inner story-container" *ngIf="utmRoutes.selectedStop">
    <div class="pad">
      <app-selected-stop></app-selected-stop>
    </div>
  </div>
</div>

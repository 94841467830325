<div>
  <div>
    <div
      class="section section-header"
      [style.background-image]="(content?.photo | toCssUrl)"
    >
      <div>
        <div>
          <div>
            <h1 [innerHtml]="content?.title"></h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="section section-ck">
      <div class="pad">
        <div [innerHtml]="content?.body"></div>
      </div>
    </div>
  </div>
</div>

export const environment = {
  dev: true,
  apiUrl: 'https://data.utrechttimemachine.nl/rest/',
  imageBaseUrl: 'https://data.utrechttimemachine.nl',
  audioBaseUrl: 'https://data.utrechttimemachine.nl',
  geoJsonBaseUrl: 'https://data.utrechttimemachine.nl',
  apiSuffixes: {
    mapLocations: 'all_locations_with_themes',
    allThemes: 'all_themes',
    locationDetailsById: 'location_details?nid=',
    storyDetailsById: 'story_details?nid=',
    storiesByLocationId: 'stories_by_location?nid=',
    organisations: 'organisations',
    routes: 'routes',
    stopsByRoute: 'route_stops?nid=',
    mediaByStory: 'media_by_story?nid=',
    staticPage: 'page?title=',
  },
  aliasToNidUrl: 'https://data.utrechttimemachine.nl/alias_to_nid?alias=',
  mediaItemImageExtensions: ['.png', '.jpg'],
  mediaItemAudioExtensions: ['.mp3', '.ogg'],
  mediaItemYouTubePrefixToReplace: [
    'https://www.youtube.com/watch?v=',
    'https://youtu.be/',
  ],
  mediaItemYouTubeEmbedUrl: 'https://youtube.com/embed/',
  mapboxAccessToken:
    'pk.eyJ1IjoiY2Itc3R1ZGlvIiwiYSI6ImNrcDUxZW04MjBjZ3gydHF0bmUyano0bncifQ.MLaKn3TF2V4b4ICX1HJnnA',
  liveSearchUrl:
    'https://data.utrechttimemachine.nl/search_api_autocomplete/utm_search?display=livesearch&filter=livesearch&q=',
  translateUrl: 'https://services.utrechttimemachine.nl/translate',
  translateKeys: {
    themes: ['title', 'caption', 'head', 'teaser'],
    routes: ['head', 'teaser', 'title', 'text', 'type'],
    locationDetails: ['caption', 'teaser', 'head', 'title', 'text'],
    storyDetails: ['title', 'location_title'],
    mediaItem: ['title', 'caption', 'text'],
    stop: ['title', 'intro', 'location_teaser', 'location_text'],
    organisation: ['title'],
    mapLocation: ['title', 'head'],
    staticPage: ['title', 'body'],
  },
  feedbackPostUrl: 'https://services.utrechttimemachine.nl/feedback',
  backendEditUrl: 'https://data.utrechttimemachine.nl/node/',
  backendEditPostFix: '/edit',
};

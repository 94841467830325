<div class="pad">
  <app-edit-button
    [nid]="story.shownStory.getValue()?.story_id"
  ></app-edit-button>

  <h2>
    {{
      utmTranslate.getAsEnglishIfApplicable(
        story.shownStory.getValue(),
        "title",
        "title_english"
      ) | unescape
    }}
  </h2>
  <p class="story-location" *ngIf="story.shownStory.getValue()?.location_title">
    {{ "location" | translate }}:
    <a
      (click)="onLocationLinkClicked()"
      [innerHtml]="story.shownStory.getValue()?.location_title"
    ></a>
  </p>

  <app-audio-player
    [audioUrl]="story.shownStory.getValue()?.audio"
    *ngIf="story.shownStory.getValue()?.audio"
  />

  <div class="media-container">
    <div
      class="views-element-container"
      *ngFor="let mediaItem of story.shownStory.getValue()?.mediaItems"
    >
      <app-media-item [mediaItem]="mediaItem"></app-media-item>
    </div>
  </div>

  <app-feedback
    [feedbackItemId]="story.shownStory.getValue()?.story_id"
    feedbackGivenText="feedbackGiven"
    feedbackQuestionText="storyFeedbackQuestion"
  ></app-feedback>
</div>

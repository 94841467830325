<div class="map-data-path type-walking">
  <div
    id="nav_calc"
    class="nav-calc"
    *ngIf="routeDistanceStr || routeDurationStr"
  >
    <span
      >{{ routeDistanceStr }} <em>{{ routeDurationStr }}</em></span
    >
  </div>
  <div id="nav_menu" class="nav-menu dragscroll">
    <ul>
      <li class="is-home">
        <a
          data-dock="route"
          [ngClass]="{ on: !utmRoutes.selectedStop }"
          (click)="utmRoutes.selectStopByIdx(undefined)"
        >
          <div></div>
        </a>
      </li>
      <li
        *ngFor="
          let routeStop of utmRoutes.selected.getValue()?.stops;
          let stopNum = index
        "
      >
        <a
          (click)="utmRoutes.selectStopByIdx(stopNum)"
          [ngClass]="{ on: utmRoutes.selectedStopIdx.getValue() === stopNum }"
        >
          <div>
            {{ stopNum + 1 }}
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>

<div
  class="container"
  [ngClass]="[
    location === FilterLocation.Map ? 'container-map' : '',
    location === FilterLocation.Stories ? 'container-stories' : ''
  ]"
>
  <p class="heading">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    {{ title }}
  </p>
  <button class="close-button" (click)="close.emit()">
    <span>&#x2717;</span>
  </button>

  <div
    class="inner-container"
    [style.overflow-y]="overflowY"
    [style.max-height]="maxHeight"
  >
    <ng-content></ng-content>
  </div>
</div>

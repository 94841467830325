<app-filter-expanded
  [title]="'themes' | translate"
  [icon]="themes.icon"
  (close)="filters.hide(location, FilterType.Theme)"
  [location]="location"
>
  <div *ngFor="let theme of themes.all.value">
    <div class="theme-checkbox-container">
      <mat-checkbox
        [id]="theme.nid"
        [name]="theme.nid"
        [value]="theme.nid"
        (change)="themes.toggle(theme.nid)"
        [checked]="themes.isSelected(theme.nid)"
        [disableRipple]="true"
        ><span>{{ theme.title }}</span>
        <br />
        <span class="theme-head">{{ theme.head }}</span></mat-checkbox
      >
    </div>
    <div class="separator"></div>
  </div>
</app-filter-expanded>

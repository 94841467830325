<div class="org-logo" *ngIf="hasOrganisations()">
  <div class="org-logo-head">{{ "organisationsHeader" | translate }}</div>
  <div class="org-logo-hold">
    <div class="views-element-container" *ngFor="let org of organisations">
      <a [href]="org.link" [title]="org.title" target="_blank">
        <img [src]="org.logo" [alt]="org.title" />
      </a>
    </div>
  </div>
</div>

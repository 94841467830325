<ul class="menu">
  <li
    class="menu-item"
    [ngClass]="{
      'menu-item--active-trail':
        routing.getSelectedView() === SelectedView.Locations
    }"
  >
    <a (click)="clickOnLink('/')">{{ "locations" | translate }}</a>
  </li>

  <li
    class="menu-item"
    [ngClass]="{
      'menu-item--active-trail':
        routing.getSelectedView() === SelectedView.Routes ||
        routing.getSelectedView() === SelectedView.SelectedRoute
    }"
  >
    <a (click)="clickOnLink('/routes')">{{ "routes" | translate }}</a>
  </li>

  <li class="menu-item films-menu-item">
    <a href="https://film.utrechttimemachine.nl">{{ "films" | translate }}</a>
  </li>

  <li
    class="menu-item"
    [ngClass]="{
      'menu-item--active-trail':
        routing.getSelectedView() === SelectedView.About
    }"
  >
    <a (click)="clickOnLink('/over')">{{ "about" | translate }}</a>
  </li>

  <li class="menu-item language-toggle" *ngIf="showLanguageToggle">
    <app-lang-toggle></app-lang-toggle>
  </li>
</ul>

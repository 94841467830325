<div
  [ngClass]="{
    'menu-az-on': menu.azMenuIsShown.getValue(),
    'menu-mb-on': menu.mbMenuIsShown.getValue(),
    'dock-story-on': story.showingStoryView.getValue()
  }"
>
  <script>
    if (window["cordova"]) {
      const cordovaScript = document.createElement("script");
      cordovaScript.src = "cordova.js";
      document.body.appendChild(cordovaScript);
    }
  </script>

  <app-connectivity></app-connectivity>
  <app-header></app-header>

  <main role="main">
    <a id="main-content" tabindex="-1"></a>
    <div class="layout-content">
      <div>
        <article role="article">
          <div [ngClass]="{ 'map-spin': spinner.show }">
            <div></div>
          </div>

          <router-outlet></router-outlet>
        </article>
      </div>
    </div>
  </main>

  <app-footer></app-footer>

  <app-menu-button></app-menu-button>
</div>

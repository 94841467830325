<div class="dev-edit-btn-container" *ngIf="shouldBeShown() && nid">
  <a
    class="dev-edit-btn"
    [href]="
      environment.backendEditUrl + this.nid + environment.backendEditPostFix
    "
    target="_blank"
    >Edit</a
  >
</div>

<button
  [ngClass]="[
    location === FilterLocation.Map
      ? 'filter-button-map'
      : 'filter-button-stories'
  ]"
>
  <!--  <img-->
  <!--    src="https://placehold.co/300x300/EEE/31343C"-->
  <!--    alt="Button icon"-->
  <!--    class="button-icon"-->
  <!--  />-->

  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
  <ng-content></ng-content>

  <div class="badge" *ngIf="badgeText" [innerHTML]="badgeText"></div>
</button>

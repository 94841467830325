import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenseComponent } from './license.component';

@NgModule({
  declarations: [LicenseComponent],
  exports: [LicenseComponent],
  imports: [CommonModule],
})
export class LicenseModule {}

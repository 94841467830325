<div
  class="filters-container"
  [ngClass]="[
    location === FilterLocation.Map ? 'filters-container-map' : '',
    location === FilterLocation.Stories ? 'filters-container-stories' : ''
  ]"
>
  <app-time-slider-button
    *ngIf="!filter.isShowing(location, FilterType.Time)"
    [location]="location"
  />
  <app-time-slider-select
    *ngIf="filter.isShowing(location, FilterType.Time)"
    [location]="location"
  />

  <app-theme-button
    *ngIf="!filter.isShowing(location, FilterType.Theme)"
    [location]="location"
  />
  <app-theme-select
    *ngIf="filter.isShowing(location, FilterType.Theme)"
    [location]="location"
  />
</div>

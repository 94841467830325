<app-filter-expanded
  [title]="'timePeriod' | translate"
  [icon]="time.icon"
  (close)="filters.hide(location, FilterType.Time)"
  [maxHeight]="'none'"
  [overflowY]="'hidden'"
  [location]="location"
>
  <div class="slider-container">
    <mat-slider
      [min]="time.DEFAULT_MIN_YEAR"
      [max]="time.DEFAULT_MAX_YEAR"
      step="1"
      [discrete]="true"
      [displayWith]="formatLabel"
      (input)="onSliderInput($event)"
    >
      <input
        [value]="time.minYear.value"
        matSliderStartThumb
        id="min-year-input"
      />
      <input
        [value]="time.maxYear.value"
        matSliderEndThumb
        id="max-year-input"
      />
    </mat-slider>
  </div>

  <div class="show-locations-without-date-container">
    <mat-checkbox
      [checked]="time.showLocationsWithoutDate.value"
      (change)="onToggleShowLocationsWithoutDate($event)"
    >
      <span *ngIf="location === FilterLocation.Map">{{
        "showLocationsWithoutDate" | translate
      }}</span>

      <span *ngIf="location === FilterLocation.Stories">{{
        "showStoriesWithoutDate" | translate
      }}</span>
    </mat-checkbox>
    <!--    <input type="checkbox" id="show-locations-without-date-checkbox" />-->
    <!--    <label for="show-locations-without-date-checkbox">-->
    <!--      <span>Toon locaties zonder datum</span>-->
    <!--    </label>-->
  </div>
</app-filter-expanded>

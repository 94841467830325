import { Route } from '@angular/router';

export const qrCodeRoutes: Route[] = [
  {
    path: '1',
    redirectTo: 'locaties/huis-van-anna-maria-van-schurman',
    pathMatch: 'full',
  },
  {
    path: '2',
    redirectTo: 'locaties/academiegebouw',
    pathMatch: 'full',
  },
  {
    path: '3',
    redirectTo: 'locaties/minrebroederklooster',
    pathMatch: 'full',
  },
  {
    path: '4',
    redirectTo: 'locaties/janskerk',
    pathMatch: 'full',
  },
  {
    path: '5',
    redirectTo: 'locaties/universiteitsbibliotheek',
    pathMatch: 'full',
  },
  {
    path: '6',
    redirectTo: 'locaties/aartsbisschoppelijk-paleis',
    pathMatch: 'full',
  },
  {
    path: '7',
    redirectTo: 'locaties/gasthuis-leeuwenbergh',
    pathMatch: 'full',
  },
  {
    path: '8',
    redirectTo: 'locaties/sonnenborgh',
    pathMatch: 'full',
  },
  {
    path: '9',
    redirectTo: 'locaties/oude-hortus-botanicus',
    pathMatch: 'full',
  },
  {
    path: '10',
    redirectTo: 'locaties/catharijneconvent',
    pathMatch: 'full',
  },
  {
    path: '11',
    redirectTo: 'locaties/ouwe-dikke-dries',
    pathMatch: 'full',
  },
  {
    path: '12',
    redirectTo: 'locaties/huis-van-belle-van-zuylen',
    pathMatch: 'full',
  },
  {
    path: '13',
    redirectTo: 'locaties/studentenverenigingen',
    pathMatch: 'full',
  },
  {
    path: '14',
    redirectTo: 'locaties/duitse-huis',
    pathMatch: 'full',
  },
  {
    path: '15',
    redirectTo: 'locaties/jan-van-nassau',
    pathMatch: 'full',
  },
  {
    path: '16',
    redirectTo: 'locaties/huis-van-quint-ondaatje',
    pathMatch: 'full',
  },
  {
    path: '17',
    redirectTo: 'locaties/organisch-chemisch-en-hygienisch-laboratorium',
    pathMatch: 'full',
  },
  {
    path: '18',
    redirectTo: 'locaties/bartholomeus-gasthuis',
    pathMatch: 'full',
  },
  {
    path: '19',
    redirectTo: 'locaties/academisch-ziekenhuis-utrecht',
    pathMatch: 'full',
  },
  {
    path: '20',
    redirectTo: 'locaties/rudolf-magnus-instituut',
    pathMatch: 'full',
  },
  {
    path: '21',
    redirectTo: 'locaties/griftpark',
    pathMatch: 'full',
  },
  {
    path: '22',
    redirectTo: 'locaties/kliniek-voor-kleine-huisdieren',
    pathMatch: 'full',
  },
  {
    path: '23',
    redirectTo: 'locaties/paardenkathedraal-een-kliniek-voor-dieren',
    pathMatch: 'full',
  },
  {
    path: '24',
    redirectTo: 'locaties/botanische-tuinen-fort-hoofddijk',
    pathMatch: 'full',
  },
  {
    path: '25',
    redirectTo: 'locaties/ooglijdersgasthuis',
    pathMatch: 'full',
  },
  {
    path: '26',
    redirectTo: 'locaties/kromhout-kazerne',
    pathMatch: 'full',
  },
  {
    path: '27',
    redirectTo: 'locaties/westerdijk-fungal-biodiversity-instituut',
    pathMatch: 'full',
  },
  {
    path: '28',
    redirectTo: 'locaties/faculteit-diergeneeskunde',
    pathMatch: 'full',
  },
  {
    path: '29',
    redirectTo: 'locaties/prinses-maxima-centrum',
    pathMatch: 'full',
  },
  {
    path: '30',
    redirectTo: 'locaties/umcu',
    pathMatch: 'full',
  },
];

<header role="banner">
  <div>
    <div data-drupal-messages-fallback="" class="hidden"></div>
  </div>

  <div class="utm-header">
    <div class="header-inner">
      <div class="site-icon">
        <a (click)="this.router.navigateByUrl('/intro')"></a>
      </div>
      <div class="site-name">
        <a (click)="this.router.navigateByUrl('/intro')"
          >Utrecht<br />Time Machine</a
        >
      </div>
      <div class="site-live">
        <div class="views-element-container">
          <div>
            <div
              class="views-exposed-form"
              id="views-exposed-form-utm-search-livesearch"
              accept-charset="UTF-8"
            >
              <div class="input-wrapper">
                <input
                  placeholder="{{ 'search' | translate }}"
                  class="form-autocomplete form-text ui-autocomplete-input"
                  [ngClass]="{
                    'ui-autocomplete-loading': search.isLoadingLiveSearchResults
                  }"
                  type="text"
                  id="edit-livesearch"
                  name="livesearch"
                  value=""
                  size="30"
                  maxlength="128"
                  autocomplete="off"
                  (input)="onSearchInputChange($event)"
                  (blur)="search.hideLiveSearchResults()"
                  (focus)="search.showLiveSearchResults = true"
                  (keyup.enter)="search.navigateToFirstSearchResult()"
                />

                <ul
                  class="search-api-autocomplete-search"
                  [ngStyle]="{
                    visibility: search.showLiveSearchResults
                      ? 'visible'
                      : 'hidden'
                  }"
                >
                  <li
                    *ngFor="
                      let searchResult of search.liveSearchResults.getValue()
                    "
                    [innerHtml]="searchResult.label"
                    (click)="onSearchResultClicked($event, searchResult)"
                  ></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="site-menu">
        <app-menu-links></app-menu-links>
      </div>
    </div>
    <div class="header-outer"></div>
  </div>
</header>

<div
  class="menu-mb-btn"
  *ngIf="routing.getSelectedView() !== SelectedView.Story"
>
  <a (click)="menuService.toggleMbMenu()">
    <div></div>
    <div></div>
  </a>
</div>

<div class="menu-mb">
  <div>
    <div class="menu-mb-inner">
      <div class="brand" (click)="onBrandLogoClicked()">
        <div>Utrecht<br />Time Machine</div>
      </div>
      <app-menu-links></app-menu-links>
    </div>
  </div>
</div>

<div class="menu-az-btn">
  <a (click)="menuService.toggleAzMenu()">
    <div class="name">A–Z</div>
    <div class="icon"></div>
  </a>
</div>

<div class="menu-az">
  <div>
    <div class="menu-az-inner" *ngIf="menuService.azMenuIsShown">
      <div class="brand white" (click)="onBrandLogoClicked()">
        <div>Utrecht<br />Time Machine</div>
      </div>
      <div
        class="az-view-nav"
        [ngClass]="{
          'sorted-on-address': menuIsSortedOn === MenuSortOn.Address
        }"
        (click)="toggleMenuSort()"
      >
        <div>{{ "name" | translate }}</div>
        <div>
          <div class="switch">
            <div></div>
            <div></div>
          </div>
        </div>
        <div>{{ "address" | translate }}</div>
      </div>
      <div class="az-view on" data-id="a">
        <div class="views-element-container" *ngIf="locationsByLetterAreLoaded">
          <div *ngFor="let letter of alphabet">
            <h3>{{ letter }}</h3>
            <div *ngFor="let location of locationsByLetter[letter]">
              <a
                (click)="
                  map.selectLocationByUrlOrId(location.url, location.nid)
                "
                ><span
                  class="name"
                  [innerHtml]="
                    menuIsSortedOn === MenuSortOn.Title
                      ? location.title
                      : location.address
                  "
                ></span
                ><span
                  class="name-sub"
                  [innerHtml]="
                    menuIsSortedOn === MenuSortOn.Title
                      ? location.address
                      : location.title
                  "
                ></span
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

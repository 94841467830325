<div class="section section-routes">
  <div class="pad">
    <h1>{{ "routes" | translate }}</h1>
    <div class="views-element-container">
      <div class="card-hold promote">
        <div class="card" *ngFor="let r of utmRoutes.shown">
          <a (click)="selectRoute(r.url, r.nid)">
            <div class="head">
              <div>
                <div>
                  <img
                    loading="lazy"
                    [src]="r.photo"
                    [alt]="
                      utmTranslate.getAsEnglishIfApplicable(
                        r,
                        'title',
                        'title_english'
                      )
                    "
                  />
                </div>
              </div>
            </div>

            <div class="core">
              <span class="type">{{ r.type }}</span
              ><span class="name">{{
                utmTranslate.getAsEnglishIfApplicable(
                  r,
                  "title",
                  "title_english"
                )
              }}</span
              ><span class="desc">{{
                utmTranslate.getAsEnglishIfApplicable(r, "head", "head_english")
              }}</span
              ><span class="dist" *ngIf="r.duration_minutes"
                >{{ r.duration_minutes }} min</span
              >
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'app-selected-route',
  templateUrl: './selected-route.component.html',
  styleUrls: ['./selected-route.component.scss']
})
export class SelectedRouteComponent {

}

<div>
  <div class="dock-inner" data-geo="52.087122000000, 5.042116100000">
    <app-edit-button [nid]="locationDetails?.nid"></app-edit-button>

    <div class="pad">
      <div class="node-column">
        <div>
          <div class="screen-mode" data-mobile="1">
            <h1 [innerHtml]="locationDetails?.title"></h1>
            <h3>{{ locationDetails?.address }}</h3>
          </div>
          <div class="photo-hold">
            <div class="photo">
              <div>
                <app-image-viewer
                  [imageSrc]="locationDetails?.image"
                  [imageAlt]="locationDetails?.title"
                ></app-image-viewer>
              </div>
            </div>
            <div class="media-source" *ngIf="locationDetails?.source_name">
              <div class="views-element-container">
                <div>
                  <span *ngIf="locationDetails?.caption">{{
                    locationDetails?.caption
                  }}</span>
                  <span
                    *ngIf="
                      locationDetails?.source_link &&
                      locationDetails?.source_name
                    "
                    >{{ "source" | translate }}:
                    <a [href]="locationDetails?.source_link" target="_blank">{{
                      locationDetails?.source_name
                    }}</a></span
                  >
                  <span
                    *ngIf="
                      !locationDetails?.source_link &&
                      locationDetails?.source_name
                    "
                    >{{ "source" | translate }}:
                    {{ locationDetails?.source_name }}</span
                  >
                  <app-license
                    [licenseLabel]="locationDetails?.license"
                  ></app-license>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="screen-mode" data-mobile="0">
            <h1 [innerHtml]="locationDetails?.title"></h1>

            <h3
              *ngIf="
                locationDetails?.city &&
                locationDetails?.city !== locationDetails?.title
              "
            >
              {{ locationDetails?.address }}, {{ locationDetails?.city }}
            </h3>
            <h3
              *ngIf="
                (!locationDetails?.city ||
                  locationDetails?.city === locationDetails?.title) &&
                locationDetails?.address !== locationDetails?.title
              "
            >
              {{ locationDetails?.address }}
            </h3>
          </div>
          <p [innerHtml]="locationDetails?.teaser"></p>
          <div class="fold" *ngIf="locationDetails?.text">
            <a
              [ngClass]="{ on: readMoreIsShown }"
              (click)="readMoreIsShown = !readMoreIsShown"
              >{{ "readMore" | translate }}</a
            >
            <div
              class="fold-toggle"
              [ngClass]="{ 'fold-toggle-hidden': !readMoreIsShown }"
              [innerHtml]="locationDetails?.text"
            ></div>
          </div>
          <app-organisations
            [organisations]="locationDetails?.organisations"
          ></app-organisations>
        </div>
      </div>

      <div class="views-element-container" *ngIf="locationHasStories()">
        <div class="story-head">
          <h2>{{ "storiesForLocation" | translate }}</h2>
        </div>

        <app-filters [location]="FilterLocation.Stories" />

        <div class="story-hold">
          <div class="story" *ngFor="let story of shownStories">
            <a
              (click)="
                router.navigateByUrl(
                  locationDetails?.url + '?story=' + story.story_url_alias
                )
              "
            >
              <div
                class="head has-video-{{
                  storyHasVideoIcon(story) ? '1' : '0'
                }}"
              >
                <div>
                  <img loading="lazy" [src]="story?.photo" />
                </div>
              </div>
              <div class="core">
                <span
                  class="name"
                  [innerHtml]="
                    utmTranslate.getAsEnglishIfApplicable(
                      story,
                      'title',
                      'title_english'
                    )
                  "
                ></span>
              </div>
            </a>
          </div>
          <p *ngIf="shownStories.length === 0">
            <em>{{ "noStoriesFoundWithTheseFilters" | translate }}</em>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="dock-story">
    <a class="dock-story-end" (click)="onCloseStoryClicked()"></a>
    <div>
      <div id="map_dock_story">
        <div class="views-element-container">
          <div>
            <div class="dock-inner story-container">
              <app-story></app-story>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
